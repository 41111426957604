import { applyMiddleware, createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './rootReducer';
import history from '../utils/history';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'users',
    'realms',
    'educational',
    'agencyApplications',
    'agents',
    'transactions',
    'commissions',
    'admins',
    'dashboard',
    'unbarred',
    'shs',
    'reconciliation',
    'internationalization',
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const middlewares =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(thunkMiddleware, routerMiddleware(history))
    : composeWithDevTools(
        applyMiddleware(thunkMiddleware, routerMiddleware(history))
      );

export default function configureStore() {
  const store = createStore(persistedReducer, compose(middlewares));
  const persistor = persistStore(store);
  return { store, persistor };
}
