import {
    FETCHING_AGENTS_SUCCESS,
    LOADING,
    GET_ALL_BANKS,
    FETCHING_AGENT_LIST_SUCCESS,
    FETCHING_AGENT_TEAM_SUCCESS,
    SET_TEAM_ASSIGN
} from './constants';

const initialState = {
    isLoading: false,
    agents: [],
    agentNetworkList: [],
    teams: [],
    banks: [],
    errorMsg: "",
    size: 1,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload
            }
        }

        case FETCHING_AGENTS_SUCCESS: {
            return {
              ...state,
              isLoading: false,
              agents: action.payload.data.content,
            };
        }

        case SET_TEAM_ASSIGN: {
            return {
                ...state,
                isLoading: false,
                teamAssignList: action.payload
            }
        }

        case GET_ALL_BANKS: {
            return {
                ...state,
                banks: action.payload
            }
        }

        case FETCHING_AGENT_LIST_SUCCESS: {
            return {
                ...state,
                agentNetworkList: action.payload
            }
        }

        case FETCHING_AGENT_TEAM_SUCCESS: {
            return {
                ...state,
                teams: action.payload
            }
        }

        default: {
            return state
        }
    };
};