import {
  LOADING,
  FETCH_SERVICE_PROVIDERS_SUCCESS,
  ERROR,
  SET_ACTIVE_SERVICE_PROVIDER,
} from './constants';

const initialState = {
  isLoading: false,
  serviceProviders: [],
};

const setActiveServiceProvider = (state, action) => {
  const newState = {
    ...state.serviceProviders,
    content: state.serviceProviders.content.map((serviceProvider) => {
      if (serviceProvider.id === action.payload.id) {
        return {
          ...serviceProvider,
          isActive: true,
        };
      }

      return {
        ...serviceProvider,
        isActive: false,
      };
    }),
  };

  console.log(newState);

  return newState;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case FETCH_SERVICE_PROVIDERS_SUCCESS: {
      return {
        ...state,
        serviceProviders: action.payload,
        isLoading: false,
      };
    }

    case SET_ACTIVE_SERVICE_PROVIDER: {
      return {
        ...state,
        serviceProviders: setActiveServiceProvider(state, action),
        isLoading: false,
      };
    }

    case ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
