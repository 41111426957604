import {
    LOADING,
    FETCHING_INCOMING_TRANSACTIONS_SUCCESS,
    INCOMING_TRANSACTIONS_SUMMARY_METRICS,
    FETCHING_OUTGOING_TRANSACTIONS_SUCCESS,
    OUTGOING_TRANSACTIONS_SUMMARY_METRICS,
    FETCHING_WALLET_SYSTEM_SUCCESS,
    FETCHING_WALLET_SYSTEM_METRICS_SUCCESS,
    FETCHING_LOG_SUCCESS,
} from './constants';

const initialState = {
    isLoading: false,
    totalContent: 0,
    incomingTransactions: [],
    imcomingSummaryMetrics: {},
    outgoingTransactions: [],
    outgoingSummaryMetrics: {},
    walletSystem: [],
    walletSystemMetrics: {},
    walletLog: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }

        case FETCHING_INCOMING_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                incomingTransactions: action.payload,
                isLoading: false,
            };
        }

        case INCOMING_TRANSACTIONS_SUMMARY_METRICS: {
            return {
                ...state,
                imcomingSummaryMetrics: action.payload,
                isLoading: false,
            };
        }

        case FETCHING_OUTGOING_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                outgoingTransactions: action.payload,
                isLoading: false,
            };
        }

        case OUTGOING_TRANSACTIONS_SUMMARY_METRICS: {
            return {
                ...state,
                outgoingSummaryMetrics: action.payload,
                isLoading: false,
            };
        }
        case FETCHING_LOG_SUCCESS: {
            return {
              ...state,
              isLoading: false,
              walletLog: action.payload,
            };
          }

          
        case FETCHING_WALLET_SYSTEM_SUCCESS: {
            return {
                ...state,
                walletSystem: action.payload.content,
                totalContent: action.payload.totalPages,
                isLoading: false,
            };
        }

        case FETCHING_WALLET_SYSTEM_METRICS_SUCCESS: {
            return {
                ...state,
                walletSystemMetrics: action.payload,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};