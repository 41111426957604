export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const BILLS_PAYMENT_SERVICE_PROVIDERS =
  'BILLS_PAYMENT_SERVICE_PROVIDERS';
export const NEW_BILLS_PAYMENT_SERVICE_PROVIDER =
  'NEW_BILLS_PAYMENT_SERVICE_PROVIDER';
export const BILLS_PAYMENT_SERVICE_PROVIDER_DETAILS =
  'BILLS_PAYMENT_SERVICE_PROVIDER_DETAILS';
export const NEW_BILLER_CREATED = 'NEW_BILLER_CREATED';
export const BILLER_DETAILS_UPDATED = 'BILLER_DETAILS_UPDATED';
export const BILLS_PAYMENT_CATEGORIES = 'BILLS_PAYMENT_CATEGORIES';
export const ADD_BILLS_PAYMENT_CATEGORIES = 'ADD_BILLS_PAYMENT_CATEGORIES';
export const UPDATE_BILLS_PAYMENT_CATEGORIES =
  'UPDATE_BILLS_PAYMENT_CATEGORIES';
export const GET_ALL_BILLERS = 'GET_ALL_BILLERS';
export const GET_ALL_BILL_SERVICE_TYPES_BY_COUNTRY_AND_BILL_CATEGORY =
  'GET_ALL_BILL_SERVICE_TYPES_BY_COUNTRY_AND_BILL_CATEGORY';
