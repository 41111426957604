import {
  LOADING,
  ERROR,
  GET_BULK_PAYOUTS,
  GET_BULK_PAYOUT_DETAILS,
  ADD_BULK_PAYOUT,
  GET_BULK_PAYOUT_TRANSACTIONS,
  UPDATE_TRANSACTION_APPROVAL_STATUS,
} from './constants';

const initialState = {
  isLoading: false,
  bulkPayoutList: { content: [], totalPage: 1, totalElements: 0 },
  bulkPayoutTransactions: {
    content: [],
    totalPage: 1,
    totalElements: 0,
  },
  bulkPayoutDetails: {
    id: '',
    amount: 0,
    createdAt: '',
    beneficiaryPhoneNumber: '',
    transactionId: '',
  },
  error: '',
};

const updateApprovalStatuses = (transactions, updated) => {
  const newStatus = updated[0].approvalStatus;
  const allUpdated = updated.map((transaction) => transaction.id);

  return transactions.map((transaction) => {
    if (allUpdated.includes(transaction.id)) {
      transaction.approvalStatus = newStatus;
    }

    return transaction;
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case GET_BULK_PAYOUTS: {
      return {
        ...state,
        bulkPayoutList: action.payload,
      };
    }

    case GET_BULK_PAYOUT_TRANSACTIONS: {
      return {
        ...state,
        bulkPayoutTransactions: action.payload,
      };
    }

    case UPDATE_TRANSACTION_APPROVAL_STATUS: {
      return {
        ...state,
        bulkPayoutTransactions: {
          ...state.bulkPayoutTransactions,
          content: updateApprovalStatuses(
            state.bulkPayoutTransactions.content,
            action.payload
          ),
        },
      };
    }

    case GET_BULK_PAYOUT_DETAILS: {
      return {
        ...state,
        bulkPayoutDetails: action.payload,
      };
    }

    case ADD_BULK_PAYOUT: {
      const payouts = action.payload;

      return {
        ...state,
        bulkPayoutTransactions: [
          ...state.bulkPayoutTransactions?.content,
          payouts,
        ],
      };
    }

    case ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
