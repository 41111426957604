import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import {
  auth,
  agents,
  educational,
  commissions,
  agencyApplications,
  users,
  realms,
  admins,
  dashboard,
  unbarred,
  shs,
  bannerManagement,
  transactions,
  reconciliation,
  appSettings,
  billsPayment,
  kyc,
  internationalization,
  bulkPayout,
  features,
  surveys,
  payWithTransfer,
  airtimeConfiguration,
} from './ducks';

export default (history) =>
  combineReducers({
    auth,
    agents,
    educational,
    commissions,
    agencyApplications,
    users,
    realms,
    admins,
    dashboard,
    unbarred,
    shs,
    bannerManagement,
    transactions,
    reconciliation,
    appSettings,
    billsPayment,
    kyc,
    internationalization,
    bulkPayout,
    features,
    surveys,
    payWithTransfer,
    airtimeConfiguration,
    router: connectRouter(history),
  });
