import { LOADING, GET_SURVEY } from './constants';

const initialState = {
  isLoading: false,
  surveyList: {
    content: [],
    totalPages: 1,
    totalElements: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case GET_SURVEY: {
      return {
        ...state,
        surveyList: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
