import {
  LOADING,
  ERROR,
  FETCHING_COUNTRIES_SUCCESSFUL,
  FETCHING_STATES_SUCCESSFUL,
  FETCHING_LGAS_SUCCESSFUL,
  FETCHING_CITIES_SUCCESSFUL,
} from "./constants";

const initialState = {
  isLoading: false,
  countries: [],
  totalCountries: 0,
  states: [],
  totalStates: 0,
  lgas: [],
  totalLgas: 0,
  cities: [],
  totalCities: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case FETCHING_COUNTRIES_SUCCESSFUL: {
      return {
        ...state,
        countries: action.payload.content,
        totalCountries: action.payload.totalElements,
      };
    }

    case FETCHING_STATES_SUCCESSFUL: {
      return {
        ...state,
        states: action.payload.states,
        totalStates: action.payload.totalStates,
      };
    }

    case FETCHING_LGAS_SUCCESSFUL: {
      return {
        ...state,
        lgas: action.payload.lgas,
        totalLgas: action.payload.totalElements,
      };
    }

    case FETCHING_CITIES_SUCCESSFUL: {
      return {
        ...state,
        cities: action.payload.cities,
        totalCities: action.payload.totalElements,
      };
    }

    case ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
